import React from "react"
import { Link } from "gatsby"
import Nav from "../components/nav"

export default function Home() {
  return (
    <div>
      <Nav>
        <h1>Web development, model railroads, and very little else!</h1>
      </Nav>
      <main>
        <div className="card-container">
          <Link to={"https://www.stevenwareham.com/dev"}>
            <div className="card card__dark">
              <h2>Web Dev</h2>
              <p>Web development using Gatsby, WordPress, and AWS.</p>
            </div>
          </Link>

          <Link to={"https://www.stevenwareham.com/rr"}>
            <div className="card card__dark">
              <h2>Model Railroads</h2>
              <p>N Scale buildout.</p>
            </div>
          </Link>
        </div>
      </main>
    </div>
  )
}
